a.chevron {
	color: black
}

td {
	vertical-align: middle !important
}

th.type {
	width: 12%;
}

th.accessTag {
	width: 12%;
}

td.buttons {
	width: 1px;
    white-space: nowrap;
}

span.name {
	padding-left: 5px;
}