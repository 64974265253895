.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }

  * {
    box-sizing: border-box;
  }
  body {

    min-height: 100vh;
    display: flex;
    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
  }
  
  h1,h2,h3,h4,h5,h6,label,span {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
  }
  
  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-bootstrap-table table {
    table-layout: auto !important;
  }

  .container-fluid {
    padding-top: 15px;
  }